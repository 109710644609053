import React from 'react';
import PageContainer from '../components/containers/PageContainer';


function DownloadComponent() {
  return (
    <div>
      <PageContainer title='Resume'>
      </PageContainer>
    </div>
  );
}

export default DownloadComponent;